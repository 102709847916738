import axios from 'axios'
import baseUrl from './baseUrl'
import store from '../store'
const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = {token: localStorage.getItem('token')}
const totens = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const getTotens = () => totens.get('/totens').then(({data}) => store.state.totens.data = data)
.catch(errors)

export const createTotem = () => totens.post('/totens', store.state.totens.newTotem).then(({data}) => data).catch(errors)

export const updateTotem = () =>  totens.put('/totens/'+store.state.totens.editedTotem._id, store.state.totens.editedTotem)
 .then(({data}) => data)
 .catch(errors)

export const deleteTotem = () => totens.delete('/totens/'+store.state.totens.editedTotem._id).then(({data})=>data).catch(errors)