<template>
  <v-dialog v-model="$store.state.totens.dialogEditTotem" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >Editando totem: {{ $store.state.totens.editedTotem.totemId }}</span
        >
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                :value="$store.state.totens.editedTotem.totemId"
                label="Totem id"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="$store.state.totens.editedTotem.name"
                label="Nome"
                required
                :rules="[(v) => (v || '').length > 0 || 'Nome é obrigatório']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="$store.state.totens.editedTotem.region"
                label="Região"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                :value="
                  (($store.state.totens.editedTotem || {}).playlist || {})._id
                "
                label="Playlist"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                :value="($store.state.totens.editedTotem.user || {}).name"
                label="Usuário"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4"> </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
        <v-btn color="blue darken-1" text @click="save" :loading="loading">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateTotem } from "../../services/totens";
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    close() {
      this.$store.state.totens.dialogEditTotem = false;
    },
    save() {
      this.loading = true;
      updateTotem()
        .then(() => {
          this.loading = false;
          this.$store.commit("success", "Totem alterado com sucesso");
          this.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit(
            "failed",
            "Falha ao tentar salvar este totem, " + err
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>