<template>
  <v-dialog v-model="$store.state.totens.dialogNewTotem" max-width="500px">
    <v-form ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">Novo Totem</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :value="$store.state.totens.newTotem.totemId"
                  label="Totem id"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="$store.state.totens.newTotem.region"
                  label="Região"
                  required
                  :rules="rulesRegion"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="$store.state.totens.newTotem.name"
                  label="Nome"
                  required
                  :rules="[(v) => (v || '').length > 0 || 'Nome é obrigatório']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :value="($store.state.totens.newTotem.playlist || {})._id"
                  label="Playlist"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :value="$store.state.totens.newTotem.user.name"
                  label="Usuário"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="save" :loading="loading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { createTotem } from "../../services/totens";
export default {
  data: () => ({
    loading: false,
    rulesRegion: [(v) => (v || {}).length > 0 || "Região é obrigatório"],
  }),
  methods: {
    close() {
      this.$store.state.totens.dialogNewTotem = false;
    },
    async save() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return this.$store.commit("failed", "Preencha o campo em destaque");
      }
      this.loading = true;

      createTotem()
        .then((totem) => {
          this.loading = false;
          this.$store.commit("addTotem", totem);
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("failed", "Preencha o campo em destaque");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>