<template>
  <div>
    <router-view></router-view>
    <NewTotem v-if="$store.state.totens.dialogNewTotem"></NewTotem>
    <EditTotem v-if="$store.state.totens.dialogEditTotem"></EditTotem>
  </div>
</template>

<script>
// @ is an alias to /src
import NewTotem from './newTotem'
import EditTotem from './editTotem'
export default {
  name: 'Totens',
  components: {NewTotem, EditTotem}
}
</script>
